<!-- FAQ's Component -->
<template>
  <div class="faq">
    <div class="container">
      <el-row :gutter="0">
        <el-col
          :xl="17"
          :lg="17"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <div class="left-side">
            <div class="title">
              <h1>FAQS</h1>
            </div>
            <div class="desc">
              <div class="question-answer">
                <p class="question">
                  Q: How do I join Get Active Hub?
                </p>
                <p class="answer">
                  A: To join Get Active Hub you must be a current HSBC UK employee. Click on Register in the upper right-hand corner of your screen.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Where do I find the Company Code for registration?
                </p>
                <p class="answer">
                  A: The Company Code you require to register can be found on the HSBC UK intranet, British Cycling Partnership page.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: How much does it cost to join Get Active Hub?
                </p>
                <p class="answer">
                  A: Get Active Hub is free for current HSBC UK employees.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Can family members or friends register?
                </p>
                <p class="answer">
                  A: Get Active Hub is only for current HSBC UK employees.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: What happens to my membership if I leave HSBC UK?
                </p>
                <p class="answer">
                  A: Get Active Hub is only for current HSBC UK employees. If you leave the employment of HSBC UK, your Get Active Hub account will be deleted.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: What are points?
                </p>
                <p class="answer">
                  A: Points are earned as you are active on the Get Active Hub app.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: How many points can I earn?
                </p>
                <p class="answer">
                  A: There is no limit to the number of points that you can earn in one day, a week or a year. The number of points you have will be displayed on your profile page.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Can I earn points for completing both a ride and a walk on the same day?
                </p>
                <p class="answer">
                  A: Yes all activity recorded in the app will earn you points.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: What are offers?
                </p>
                <p class="answer">
                  A: The points you earn unlock offers. These offers include discounts and prize draws for a chance to win money-can’t-buy experiences.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Can I link to my Strava account?
                </p>
                <p class="answer">
                  A: Shortly you will be able to link Get Active Hub to your Strava account by visiting your profile page and clicking ‘Link Strava’.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Can I link any other fitness trackers?
                </p>
                <p class="answer">
                  A: No.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Who has access to the personal information I submit at Get Active Hub?
                </p>
                <p class="answer">
                  A: Confidentiality is a top priority at Get Active Hub. We are committed to maintaining the highest level of confidentiality with all of the information we receive from our users. Our Privacy Statement assures you that the information you share with Get Active Hub is kept confidential and fully secure.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Are there tax implications with respect to offers?
                </p>
                <p class="answer">
                  A: It is the responsibility of the member to determine what offers if any are taxable.
                </p>
              </div>
              <div class="question-answer">
                <p class="question">
                  Q: Who do I contact with technical or offer inquiries?
                </p>
                <p class="answer">
                  A: Please email <a href="mailto:hsbcuk.british.cycling@hsbc.com">hsbcuk.british.cycling@hsbc.com</a>
                </p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :xl="7"
          :lg="7"
          class="hidden-md-and-down"
        >
          <sidebar
            :is-related="false"
            :is-public="true"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/modules/feed/components/sidebar'
import store from '@/store'

export default {
  components: {
    Sidebar
  },

  beforeCreate () {
    if (!store.state.isLogged) {
      this.$router.push({ name: 'auth-login' })
    }
  },
}
</script>

<style lang="scss">
.faq {
  @media (max-width: 991px) {
    overflow: hidden;
  }
  .left-side {
    position: relative;
    background-color: $white-color;
    padding: 0 65px 42px 0;
    &:before {
      content: "";
      position: absolute;
      background-color: #fff;
      left: -90%;
      width: 90%;
      height: 100%;
      @media (max-width: 991px) {
        content: "";
        position: absolute;
        background-color: #fff;
        left: -20%;
        width: 140%;
        height: 100%;
        z-index: -1;
      }
    }
    @media (max-width: 991px) {
      padding: 0;
    }
    .title {
      //border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: #EDECED;
      h1 {
        margin-top: 0;
        padding-top: 30px;
        @media (max-width: 575px) {
          font-size: 29px;
          line-height: 40px;
        }
      }
    }
    .desc {
      margin: 20px 0 50px 0;
      //p {
      //  margin: 0 auto 30px auto;
      //}
      .question-answer {
        margin-bottom: 14px;
        p {
          margin: 0;
          font-size: 18px;
          &.question {
            color: $primary-color
          }
          &.answer {
            margin-top: 5px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
</style>